<template>
  <div class="company-search-wrapper">
    <ValidationProvider
      v-if="!freeTextCompany && !noCompanySearch"
      class="ui field custom-search-box m-b-1"
      rules="no_leading_space|required"
      name="company"
      vid="company"
      #default="{ errors }"
      tag="div"
      v-show="!isCantFindCompany"
    >
      <label> {{ searchLabel }}: </label>
      <div class="ui icon input input-group group-right">
        <i class="icon material-icons" v-if="!freeTextCompany">search</i>
        <vue-instant
          ref="typeAhead"
          name="company"
          placeholder="Company name"
          v-model="typeahead.value"
          :suggestion-attribute="typeahead.suggestionAttribute"
          :show-autocomplete="true"
          :autofocus="false"
          :findSimilar="false"
          :allowSimilar="true"
          :suggestions="typeahead.suggestions"
          :suggestOnAllWords="false"
          @selected="selectCompany"
          @enter="handleOnEnter"
        ></vue-instant>
      </div>
      <FieldError class="ui pointing above red basic label" v-bind="{ errors }" />
    </ValidationProvider>
    <ValidationProvider class="ui field" :class="[noCompanySearch ? 'm-b-1' : 'm-b-5']" rules="required" name=" " vid="companyName" #default="{ errors }" tag="div" v-else>
      <label> {{ searchLabel }}: </label>
      <input class="ui input" name="companyName" type="text" v-model="typeahead.value" />
      <div>
        <FieldError class="ui pointing above red basic label" v-bind="{ errors }" />
      </div>
    </ValidationProvider>
    <ValidationProvider class="ui field m-b-5" rules="required" name=" " vid="companyName" #default="{ errors }" tag="div" v-if="isCantFindCompany">
      <label v-if="hasUserCompanyAttached">What is your client's company name?</label>
      <label v-else>What is your company name?</label>
      <input class="ui input" name="companyName" v-model="typeahead.value" />
      <div>
        <FieldError class="ui pointing above red basic label" v-bind="{ errors }" />
      </div>
    </ValidationProvider>
    <ValidationProvider
      class="ui field m-b-5 cant-find-company"
      rules="required"
      name=" "
      vid="isTrading"
      tag="div"
      v-if="$config.whitelabel.components.companySearch.cantFindCompany && !freeTextCompany && !$config.whitelabel.signUp.noCompanySearch"
    >
      <Checkbox v-model="isCantFindCompany" name="isCantFindCompany" @input="clearCompany" :label="cantFindCompanyLabel" />
    </ValidationProvider>
    <ValidationProvider class="ui field m-b-5" rules="required" name=" " vid="isSoleTrader" tag="div" v-if="isCantFindCompany && $config.whitelabel.signUp.soleTraderSignupEnabled">
      <Checkbox v-model="isSoleTrader" name="isSoleTrader" :label="`${hasUserCompanyAttached ? 'My client is' : 'I am'} a sole trader`" />
    </ValidationProvider>
    <ValidationProvider
      class="ui field m-b-1"
      rules="required"
      name=" "
      vid="companyNumber"
      #default="{ errors }"
      tag="div"
      v-if="((isCantFindCompany && !isSoleTrader) || noCompanySearch) && $config.whitelabel.components.companySearch.allowAddCompanyNumber && !$config.whitelabel.signUp.noCompanySearch"
    >
      <label>{{ companyNumberLabel }}</label>
      <input class="ui input" name="companyNumber" v-model="companyNumber" />
      <div>
        <FieldError class="ui pointing above red basic label" v-bind="{ errors }" />
      </div>
    </ValidationProvider>
    <ValidationProvider
      class="field"
      :rules="'required'"
      name=" "
      vid="countryOfResidence"
      #default="{ errors }"
      tag="div"
      v-if="isCantFindCompany && $config.whitelabel.signUp.countryRequired"
    >
      <label>Country</label>
      <select :class="[{ empty: countryOfResidence === '' }, 'ui fluid selection dropdown']" v-model="countryOfResidence" name="countryOfResidence" tabindex="5">
        <option value hidden disabled selected>Choose one</option>
        <option v-for="option in countriesOptions" :value="option.value" :key="option.value">{{ option.label }}</option>
      </select>
      <FieldError class="ui pointing above red basic label" v-bind="{ errors }" />
    </ValidationProvider>
    <ValidationProvider
      class="ui field m-b-1"
      rules="required"
      name=" "
      vid="postcode"
      #default="{ errors }"
      tag="div"
      v-if="isCantFindCompany === true && countryOfResidence === 'United Kingdom' && isSignUpRoute"
    >
      <label>What is your company's postal code?</label>
      <input class="ui input" name="postcode" v-model="postcode" />
      <div>
        <FieldError class="ui pointing above red basic label" v-bind="{ errors }" />
      </div>
    </ValidationProvider>
    <modal name="soleTraderConfirmationModal" height="auto" :scrollable="true" :width="560">
      <section class="journey-documents-modal text-center">
        <h2 class="ui title text-center m-b-1">Are you sure?</h2>
        <div class="ui text-lg text-center m-b-1">
          <p v-if="hasUserCompanyAttached">
            Hey there! As your client is a sole trader, please confirm that more than 50% of any business funding we help you acquire for them will be used for commercial purposes.
          </p>
          <p v-else>Hey there! As you are a sole trader, please confirm that more than 50% of any business funding we help you acquire will be used for commercial purposes.</p>
        </div>
        <button class="ui button btn-secondary circular m-r-10" type="button" @click="toggleIsSoleTraderModal(true)">Cancel</button>
        <button class="ui button btn-primary circular business-gradient" type="button" @click="toggleIsSoleTraderModal()">Accept</button>
      </section>
    </modal>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Checkbox from '@/components/forms/Checkbox'
import { findCompanies, getCompanyEntityTypeCode } from '@/api/company'
import FieldError from '@/components/forms/FieldError'
import { countriesOptions, countryNameFromCode } from '@/utils/countries'
export default {
  name: 'CompanySearch',
  props: {
    freeTextCompany: {
      type: Boolean,
      default: false
    },
    noCompanySearch: {
      type: Boolean,
      default: false
    },
    hasUserCompanyAttached: {
      type: Boolean,
      default: false
    },
    isSoleTraderModalOpen: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: [String, Number]
    }
  },
  components: {
    Checkbox,
    FieldError,
    ValidationProvider
  },
  mounted() {
    // vue-instant doesn't support tabindexes
    // workaround could be to PR to their repository
    const searchInputList = document.querySelectorAll('.ui.input')
    let initialIndex = this.tabindex
    // If in sign up page and has 'isCantFindCompany' checkbox, sets the initial tabindex value for the checkbox element.
    const checkboxCantFindCompany = document.getElementsByName('isCantFindCompany')

    if (checkboxCantFindCompany.length) {
      checkboxCantFindCompany[0].setAttribute('tabindex', this.tabindex)

      // Adds one to the initial tabindex value so the other elements can have the correct values.
      initialIndex++
    }
    const searchInput = document.querySelector('.sbx-searchbox__input')
    if (searchInput && this.tabindex) {
      searchInput.setAttribute('tabindex', initialIndex)
      initialIndex++
    }
    // Setting up the the correct tabindex value for each subsequent element based on the initial tabindex value.
    if (searchInputList.length && this.tabindex) {
      searchInputList.forEach(inputElement => {
        if (!inputElement.className.includes('input-group')) {
          inputElement.setAttribute('tabindex', initialIndex)
          initialIndex++
        }
      })
    }
  },
  data() {
    return {
      companyName: null,
      company: null,
      typeahead: {
        value: '',
        suggestionAttribute: 'title',
        suggestions: this.getSuggestions
      },
      countriesOptions,
      isSoleTrader: false,
      isCantFindCompany: false,
      companyNumber: null,
      countryOfResidence: this.$config.whitelabel.country.defaultCountry,
      defaultCountryCode2: this.$config.whitelabel.country.defaultCountryCode2,
      postcode: null
    }
  },
  computed: {
    displayPostcode() {
      return this.isCantFindCompany === true && this.isSoleTrader === true && this.countryOfResidence === 'United Kingdom' && this.isSignUpRoute
    },
    isSignUpRoute() {
      return this.$route.name.includes('sign-up')
    },
    companyDetails() {
      return `${this.companyName}|${this.company}|${this.typeahead ? this.typeahead.value : ''}|${this.isSoleTrader}|${this.isCantFindCompany}|${this.companyNumber}|${
        this.countryOfResidence
      }|${this.postcode}`
    },
    searchLabel() {
      if (this.freeTextCompany || this.noCompanySearch) {
        return this.$dictionary.companyNameLabel
      }
      return 'Search company name'
    },
    companyNumberLabel() {
      if (this.hasUserCompanyAttached) {
        return `What is your client's ${this.$dictionary.companyNumber}?`
      }
      return `What is your ${this.$dictionary.companyNumber}?`
    },
    cantFindCompanyLabel() {
      return `I can't find my ${this.hasUserCompanyAttached ? "client's " : ''}company`
    }
  },
  watch: {
    displayPostcode(displayPostcode) {
      if (!displayPostcode) {
        this.postcode = null
      }
    },
    companyDetails() {
      this.emitCompanyDetails()
    },
    isSoleTraderModalOpen(isSoleTraderModalOpen) {
      this.triggerModal(isSoleTraderModalOpen)
    }
  },
  methods: {
    toggleIsSoleTraderModal(cancelled) {
      if (cancelled) {
        this.$emit('isSoleTraderConfirmed', false)
      } else {
        this.$emit('isSoleTraderConfirmed', true)
      }
      if (this.isSoleTraderModalOpen) {
        this.triggerModal(!this.isSoleTraderModalOpen)
        this.$emit('isSoleTraderModalOpen', false)
      } else {
        this.triggerModal(!this.isSoleTraderModalOpen)
        this.$emit('isSoleTraderModalOpen', true)
      }
    },
    triggerModal(active) {
      if (active) {
        this.$modal.show('soleTraderConfirmationModal')
        this.$emit('isSoleTraderModalOpen', true)
      } else {
        this.$modal.hide('soleTraderConfirmationModal')
        this.$emit('isSoleTraderModalOpen', false)
      }
    },
    getCountry() {
      if (this.isCantFindCompany) {
        return this.countryOfResidence
      }
      if (this.company?.registeredAddress?.country) {
        return this.company.registeredAddress.country
      }
      if (this.company?.countryCode) {
        return countryNameFromCode(this.company.countryCode)
      }
      return this.$config.whitelabel.country.defaultCountry
    },
    async emitCompanyDetails() {
      const companyDetails = {
        companyName: this.typeahead.value,
        registrationNumber: this.company ? this.company.companyRegistrationNumber : this.companyNumber,
        country: this.getCountry(),
        postcode: this.postcode,
        isCantFindCompany: this.isCantFindCompany,
        sourceType: this.getSourceType()
      }
      if (this.$config.whitelabel.components.companySearch.soleTraderConfirmation && this.company && this.company.companyRegistrationNumber) {
        const response = await getCompanyEntityTypeCode(this.company.companyRegistrationNumber)
        this.$emit('input', {
          ...companyDetails,
          entityTypeCode: response.data ? response.data.entityTypeCode : null
        })
      } else {
        this.$emit('input', companyDetails)
      }
    },
    async emitWithEntityTypeCode() {
      if (this.company && this.company.companyRegistrationNumber) {
        const response = await getCompanyEntityTypeCode(this.company.companyRegistrationNumber)
        this.company = {
          entityTypeCode: response.data ? response.data.entityTypeCode : null
        }
      }
    },
    getSourceType() {
      if (this.company) {
        return 'FromRegistrar'
      }
      if (this.isSoleTrader) {
        return 'SoleTrader'
      }
      return 'Entered'
    },
    countyrCode(c) {
      return c.countryCode ? `(${c.countryCode})` : ''
    },
    companyRegistration(c) {
      return c.companyRegistrationNumber ? `(ABN: ${c.companyRegistrationNumber})` : ''
    },
    companyRegistrationNumber(c) {
      return c.companyRegistrationNumber ? `(${c.companyRegistrationNumber})` : ''
    },
    getSuggestions(value, callback) {
      this.company = null
      if (value.length < 3) return
      const requestData = {
        companyName: value,
        skip: 0,
        take: 0
      }
      const companiesPromise = findCompanies(requestData)
        .then(response =>
          response.data.companies
            .sort((a, b) => (a.name.toLowerCase().indexOf(value.toLowerCase()) > b.name.toLowerCase().indexOf(value.toLowerCase()) ? -1 : 1))
            .map(c => {
              if (this.$config.whitelabel.components.companySearch.countryCode) {
                return { ...c, title: `${c.name} ${this.countyrCode(c)} ${this.companyRegistrationNumber(c)}` }
              } else {
                return { ...c, title: `${c.name} ${this.companyRegistration(c)}` }
              }
            })
        )
        .catch(e => e)
      callback(companiesPromise)
    },
    selectCompany(company) {
      this.company = company
    },
    handleOnEnter(ev) {
      ev && ev.preventDefault()
    },
    clearCompany() {
      this.company = null
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables';
.company-search-wrapper {
  position: relative;
  @media only screen and (min-width: 1023px) {
    .cant-find-company {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 12;
    }
  }
}
.form {
  margin: 0 auto;
  width: 464px;
  @media only screen and (min-width: 1023px) {
    margin-top: 4rem; // TODO :: Align with SignUpHeader
  }
  @media only screen and (max-width: 770px) {
    padding: 1rem;
    width: 100%;
    .ui.title {
      justify-content: flex-start;
    }
    .two.fields {
      display: block;
    }
    /deep/ .field {
      width: 100%;
    }
  }
  .field.custom-search-box {
    position: relative;
    display: block;
    z-index: 11;
    /deep/ {
      .input[type='text'],
      .sbx-searchbox__input,
      .sbx-searchbox__input-placeholder {
        font-size: 1rem;
        line-height: 1.5em;
      }
      .searchbox {
        margin-bottom: 12px;
        .group {
          right: 16px;
        }
      }
      .ui.input {
        position: relative;
        -webkit-appearance: none;
        background-color: transparent;
        z-index: 0;
        .icon {
          position: absolute;
          left: 10px;
          top: 7px;
        }
      }
      .sbx-searchbox__input {
        width: 100%;
        background-color: transparent;
        padding-left: 2.25rem;
      }
      .sbx-searchbox__input-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        -webkit-appearance: none;
        opacity: 0.5;
        z-index: -1;
        width: 100%;
        padding-left: 2.25rem;
      }
    }
  }
  .sbx-searchbox {
    width: 100%;
    /deep/ {
      input {
        width: 100%;
      }
    }
  }
}
</style>
