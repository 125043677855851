import { fundingTypes, fundingPurposesOptions, fundingRepaymentPeriodYearsOptions, fundingRepaymentPeriodMonthOptions } from '@/utils/constants'
import config from '@/config/global'
import dictionary from '@/config/dictionary'

const uploadPitchDeck = [
  {
    choice: 'single',
    field: 'dummy',
    name: 'pitchDec',
    veeAs: 'pitch deck',
    label: 'Upload pitch deck:',
    dependsOn: [
      {
        fieldName: 'fundingType',
        values: ['Equity']
      }
    ]
  }
]

const fundingRepaymentPeriod = [
  [
    {
      field: 'dummy',
      name: 'fundingRepaymentPeriod',
      label: 'What is your preferred repayment period for loan funding?',
      dependsOn: [
        {
          fieldName: 'fundingType',
          values: ['Loans']
        }
      ]
    }
  ],
  [
    {
      choice: 'single',
      field: 'select',
      type: '',
      isInline: true,
      name: 'fundingRepaymentPeriodYears',
      veeAs: 'preferred repayment period',
      label: '',
      placeholder: 'years',
      rules: 'required|numeric',
      options: fundingRepaymentPeriodYearsOptions,
      dependsOn: [
        {
          fieldName: 'fundingType',
          values: ['Loans']
        }
      ],
      selected: ''
    },
    {
      choice: 'single',
      field: 'select',
      type: '',
      isInline: true,
      name: 'fundingRepaymentPeriodMonth',
      veeAs: 'preferred repayment period',
      label: '',
      placeholder: 'months',
      rules: 'required|numeric|required_if_falsy:fundingRepaymentPeriodYears,0',
      options: fundingRepaymentPeriodMonthOptions,
      dependsOn: [
        {
          fieldName: 'fundingType',
          values: ['Loans']
        },
        {
          fieldName: 'fundingRepaymentPeriodYears',
          rule(selected) {
            return String(selected) !== '10'
          }
        }
      ],
      selected: ''
    }
  ]
]

export default {
  name: 'fundingInfoForm',
  routeName: 'funding-requirements',
  formTitle: 'Funding Requirements',
  navTitle: 'Funding Requirements',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      title: 'Funding needs',
      fields: [
        [
          {
            choice: 'multiple',
            field: 'input',
            type: 'checkbox',
            name: 'fundingType',
            veeAs: '',
            label: 'Please select the type(s) of funding you are looking for:',
            placeholder: '',
            rules: 'required',
            columns: 1,
            options: fundingTypes,
            selected: []
          }
        ],
        uploadPitchDeck,
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'totalFundingRequired',
            veeAs: 'total funding',
            label: 'How much funding do you require?',
            placeholder: 'numbers only',
            rules: 'required|numeric|min_value:0',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'multiple',
            field: 'input',
            type: 'checkbox',
            name: 'fundingPurposes',
            veeAs: 'activities',
            label: 'What activities would you like to fund?',
            placeholder: '',
            rules: 'required',
            columns: 2,
            options: fundingPurposesOptions,
            selected: []
          }
        ],
        [
          {
            choice: 'single',
            field: 'textarea',
            type: 'text',
            name: 'summary',
            veeAs: ' ',
            label: 'Please describe what you are looking to raise for?',
            placeholder: '',
            rules: 'required',
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        ...fundingRepaymentPeriod,
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'newHiresPlan',
            veeAs: ' ',
            label: 'How many people are you planning to hire with your funding in the next 12 months? If none - put in 0',
            placeholder: '',
            rules: 'required|numeric',
            dependsOn: [
              {
                fieldName: 'fundingType',
                values: ['Grants']
              }
            ],
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'researchAndDevelopmentSpend',
            veeAs: ' ',
            label: 'What is your estimated research and development spend on new product or process? If no - put in 0',
            placeholder: '',
            rules: 'required|numeric',
            dependsOn: [
              {
                fieldName: 'fundingType',
                values: ['Grants']
              }
            ],
            selected: '',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            }
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'turnoverLastYear',
            veeAs: ' ',
            label: `What was your ${dictionary.turnover} in the last financial year?`,
            placeholder: '',
            rules: 'required|numeric',
            selected: '',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            }
          },
          {
            field: 'dummy'
          }
        ]
      ]
    }
  ]
}
