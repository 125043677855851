var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui stackable sixteen column padded grid row funding-requirements"},[_c('main',{staticClass:"twelve wide column col-xs-12 col-sm-12 col-md-8 col-lg-8"},[_c('div',{staticClass:"ui container"},[_c('JourneyHeader',{staticClass:"m-b-15"}),_c('h2',{staticClass:"ui title m-b-15 h2"},[_vm._v("Your funding requirements")]),_c('div',{staticClass:"ui m-b-15"},[_c('DynamicForm',{attrs:{"submit-promise":_vm.submitPromise,"submit-text":"Next"},on:{"df-submit":_vm.submitFundingApplication},scopedSlots:_vm._u([(_vm.displayCompanyForm)?{key:"defaultTop",fn:function(){return [_c('company-search',{class:'funding-company-search',attrs:{"isSoleTraderModalOpen":_vm.isSoleTraderModalOpen,"hasUserCompanyAttached":Boolean(_vm.hasUserCompanyAttached),"freeTextCompany":_vm.$config.whitelabel.signUp.signUpFreeTextCompany,"noCompanySearch":_vm.$config.whitelabel.signUp.noCompanySearch},on:{"isSoleTraderModalOpen":_vm.toggleSoleTraderModal,"isSoleTraderConfirmed":_vm.confirmSoleTrader},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})]},proxy:true}:null,{key:"pitchDec",fn:function(ref){
var field = ref.field;
var fieldSet = ref.fieldSet;
var group = ref.group;
var value = ref.value;
return [(!field.dependsOn || _vm.isFieldVisible(field, group, value.groups))?_c('ValidationProvider',{key:field.name,staticClass:"field pitchDecField",attrs:{"rules":{ required: { allowFalse: false } },"name":field.veeAs ? field.veeAs : field.name,"vid":field.name,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('label',[_vm._v(" "+_vm._s(field.label)+" ")])]),_c('div',{staticClass:"box"},[_c('ProductRequiredDocuments',_vm._b({staticClass:"profile",on:{"change":_vm.documentsListChangeHandler}},'ProductRequiredDocuments',{ documentsList: _vm.documentsList, requiredDocumentsList: _vm.requiredDocumentsList },false))],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isAllDocumentUploaded),expression:"isAllDocumentUploaded"}],staticStyle:{"display":"none"},attrs:{"type":"text","name":field.name},domProps:{"value":(_vm.isAllDocumentUploaded)},on:{"input":function($event){if($event.target.composing){ return; }_vm.isAllDocumentUploaded=$event.target.value}}}),(field.field !== 'survey-documents')?_c('FieldError',_vm._b({staticClass:"ui pointing above red basic label"},'FieldError',{ errors: errors },false)):_vm._e()]}}],null,true)}):_c('div')]}},{key:"fundingRepaymentPeriod",fn:function(ref){
var field = ref.field;
var fieldSet = ref.fieldSet;
var group = ref.group;
var value = ref.value;
return [(!field.dependsOn || _vm.isFieldVisible(field, group, value.groups))?_c('div',{staticClass:"field funding-repayment-period-field"},[_c('label',[_vm._v(_vm._s(field.label))])]):_vm._e()]}}],null,true),model:{value:(_vm.fundingRequirementsForm),callback:function ($$v) {_vm.fundingRequirementsForm=$$v},expression:"fundingRequirementsForm"}},[_c('ValidationProvider',{staticClass:"ui field radio-parent m-b-1",attrs:{"rules":"required","name":" ","vid":"isTrading","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Are you trading? ")]),_c('Radio',{staticClass:"ui radio field-inline",attrs:{"name":"isTrading","label":"yes","model-value":true},model:{value:(_vm.isTrading),callback:function ($$v) {_vm.isTrading=$$v},expression:"isTrading"}}),_c('Radio',{staticClass:"ui radio field-inline",attrs:{"name":"isTrading","label":"no","model-value":false},model:{value:(_vm.isTrading),callback:function ($$v) {_vm.isTrading=$$v},expression:"isTrading"}}),_c('div',[_c('FieldError',_vm._b({staticClass:"ui pointing above red basic label"},'FieldError',{ errors: errors },false))],1)]}}])}),(_vm.isTrading === true)?_c('ValidationProvider',{staticClass:"ui field m-b-1",attrs:{"rules":"required","name":" ","vid":"tradingAgeRanges","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" How long ago did you make your first commercial sale? ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tradingAgeRanges),expression:"tradingAgeRanges"}],staticClass:"ui selection dropdown",class:{ empty: _vm.tradingAgeRanges === '' },attrs:{"name":"tradingAgeRanges"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.tradingAgeRanges=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","hidden":"","disabled":"","selected":""}}),_vm._l((_vm.tradingAgeRangesOptions),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(_vm._s(option.label))])})],2),_c('div',[_c('FieldError',_vm._b({staticClass:"ui pointing above red basic label"},'FieldError',{ errors: errors },false))],1)]}}],null,false,98310668)}):_vm._e(),_c('ValidationProvider',{staticClass:"ui field m-b-1",attrs:{"rules":"required","name":" ","vid":"currentlyBank","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Where do you currently bank? ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentlyBank),expression:"currentlyBank"}],staticClass:"ui selection dropdown",class:{ empty: _vm.currentlyBank === '' },attrs:{"name":"currentlyBank"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.currentlyBank=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","hidden":"","disabled":"","selected":""}}),_vm._l((_vm.$config.whitelabel.forms.currentlyBankOptions),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(_vm._s(option.label))])})],2),_c('div',[_c('FieldError',_vm._b({staticClass:"ui pointing above red basic label"},'FieldError',{ errors: errors },false))],1)]}}])}),(_vm.currentlyBank === 'Other')?_c('ValidationProvider',{staticClass:"ui field m-b-1",attrs:{"rules":"required","name":" ","vid":"currentlyBankOther","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Please enter the name of your current bank ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentlyBankOther),expression:"currentlyBankOther"}],staticClass:"ui input",attrs:{"name":"currentlyBankOther"},domProps:{"value":(_vm.currentlyBankOther)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currentlyBankOther=$event.target.value}}}),_c('div',[_c('FieldError',_vm._b({staticClass:"ui pointing above red basic label"},'FieldError',{ errors: errors },false))],1)]}}],null,false,2906229554)}):_vm._e()],1)],1)],1)]),(_vm.$config.whitelabel.components.journeyHelperText)?_c('aside',{staticClass:"ui four wide column bg-body col-md-4 col-lg-4"},[_vm._m(0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui container help transparent noborder"},[_c('div',{staticClass:"content"},[_c('p',{staticClass:"ui text dark"},[_vm._v(" The more we understand about the funding you require, the slicker the process will be."),_c('br'),_c('br'),_vm._v(" We work with world class partners to ensure we’re finding you only the best funding and savings options. ")])])])}]

export { render, staticRenderFns }