<template src="./fundingRequirements.html"></template>
<style src="./fundingRequirements.scss" lang="scss" scoped></style>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companyGetters, companyStateRoutine } from '@/store/modules/company/routines'
import { fundingGetters, fundingAppRoutine } from '@/store/routines'
import { getFundingApplication, patchFundingApplication } from '@/api/fundingApplication'
import { getDocumentsList } from '@/api/document'
import DynamicForm from '@/components/forms/DynamicForm'
import JourneyHeader from '../journeyHeader'
import ProductRequiredDocuments from '@/components/ProductRequiredDocuments'
import fundingForm from './fundingRequirements.form'
import deepcopy from 'deepcopy'
import { prefillFormData, flattenGroupFields, isFieldVisible } from '@/utils/formHelpers'
import { setFinancialData } from '@/api/financialData'
import { createCompany, patchCompany } from '@/api/company'
import Checkbox from '@/components/forms/Checkbox'
import { userRoutine, authGetters } from '@/store/modules/auth/routines'
import { toastDefaultOptions } from '../../../config/vue-toast'
import { ValidationProvider } from 'vee-validate'
import FieldError from '@/components/forms/FieldError'
import FieldBuilder from '@/components/forms/FieldBuilder'
import Radio from '@/components/forms/Radio'
import { sendBasicEventToAmplitude, journeyStepsButtonAmplitudeEvent } from '@/utils/utils'
import { amplitudeEvents, tradingAgeRangesOptions } from '@/utils/constants'
import CompanySearch from '@/components/CompanySearch'
import confirmSoleTraderModal from '@/mixins/confirmSoleTraderModal'
import steps from '@/features/journey/steps'

export default {
  name: 'FundingRequirements',
  components: {
    CompanySearch,
    Radio,
    Checkbox,
    JourneyHeader,
    DynamicForm,
    ProductRequiredDocuments,
    ValidationProvider,
    FieldError,
    FieldBuilder
  },
  mixins: [confirmSoleTraderModal, steps],
  data() {
    return {
      fundingRequirementsForm: {},
      submitPromise: null,
      documentsList: [],
      journeyStepsButtonAmplitudeEvent,
      isSoleTraderConfirmedWatcher: null,
      company: null,
      isTrading: true,
      tradingAgeRanges: null,
      currentlyBank: null,
      currentlyBankOther: null,
      tradingAgeRangesOptions: tradingAgeRangesOptions
    }
  },
  computed: {
    ...mapGetters({
      user: authGetters.USER,
      userCompanies: companyGetters.COMPANIES_LIST,
      companyId: companyGetters.COMPANY_ID,
      fundingApplicationId: fundingGetters.FUNDING_APP_ID,
      isPitchDeckError: false
    }),
    requiredDocumentsList() {
      return [
        {
          isDocumentUploaded: !!this.documentsList.find(doc => doc.title === 'Pitch Deck' && doc.type === 'FundingDocuments'),
          key: 'hasPitchDeck',
          label: 'Pitch deck',
          title: 'Pitch Deck',
          type: 'FundingDocuments'
        }
      ]
    },
    fieldByName() {
      return this.getFieldsByName(this.fundingRequirementsForm.groups[0])
    },
    isAllDocumentUploaded() {
      return this.requiredDocumentsList.find(doc => doc.isDocumentUploaded === false) === undefined
    },
    hasUserCompanyAttached() {
      return Array.isArray(this.userCompanies) && this.userCompanies.length
    },
    displayCompanyForm() {
      return !this.hasUserCompanyAttached
    }
  },
  methods: {
    ...mapActions({
      requestUser: userRoutine.TRIGGER,
      setFundingApplication: fundingAppRoutine.TRIGGER,
      storeRegistrationState: companyStateRoutine.TRIGGER
    }),
    isClients() {
      return false
    },
    isFieldVisible,
    showError(message) {
      this.$toasted.clear()
      this.$toasted.show(`${message}`, { ...toastDefaultOptions, type: 'error', icon: 'error', singleton: true })
    },
    mapEventsToAmplitude(fundingTypes, buttonType) {
      let eventToSend = null
      if (fundingTypes && fundingTypes.length > 0 && fundingTypes.length === 3) {
        eventToSend = amplitudeEvents.businessOnboarding[`ALL_FUNDING_REQUIREMENT_${buttonType}`]
        sendBasicEventToAmplitude(this.$ma, eventToSend)
      } else {
        fundingTypes.forEach(type => {
          const typeTransform = type.toUpperCase()
          eventToSend = amplitudeEvents.businessOnboarding[`${typeTransform}_FUNDING_REQUIREMENT_${buttonType}`]
          sendBasicEventToAmplitude(this.$ma, eventToSend)
        })
      }
    },
    async doCompanyRequest() {
      if (this.displayCompanyForm) {
        this.company.soleTraderConfirmed = this.isSoleTraderConfirmed
      }
      const res = await createCompany(this.company)
      await this.requestUser()
      return res.data.companyId
    },
    async submitFundingApplication(form) {
      if (this.displayCompanyForm && this.shouldConfirmSoleTrader) {
        this.toggleSoleTraderModal(true)
        this.$nextTick(() => {
          if (this.isSoleTraderConfirmedWatcher) {
            this.isSoleTraderConfirmedWatcher()
          }
          this.isSoleTraderConfirmedWatcher = this.$watch('isSoleTraderConfirmed', isSoleTraderConfirmed => {
            if (isSoleTraderConfirmed) {
              this.submitFundingApplication(form)
            }
          })
        })

        return
      }
      this.mapEventsToAmplitude(form.data.fundingType, 'NEXT')
      const { fundingRepaymentPeriodYears = null, fundingRepaymentPeriodMonth = null, ...formData } = form.data
      let fundingRepaymentPeriod = null
      if (fundingRepaymentPeriodYears !== null && fundingRepaymentPeriodMonth !== null) {
        const fpYears = fundingRepaymentPeriodYears ? parseInt(fundingRepaymentPeriodYears, 10) : 0
        const fpMonths = fundingRepaymentPeriodMonth ? parseInt(fundingRepaymentPeriodMonth, 10) : 0
        fundingRepaymentPeriod = fpYears * 12 + fpMonths
      }
      if (this.displayCompanyForm) {
        if (this.company && this.company.sourceType === 'Entered' && !this.company.isCantFindCompany && !this.$config.whitelabel.signUp.signUpFreeTextCompany) {
          this.showError('Please select a company.')
          return
        }
        this.sendCompanyAnalytics()
        await this.doCompanyRequest()
      }
      if (!this.$route.params && !this.$route.query.newBusiness && this.fundingApplicationId) {
        await patchFundingApplication({
          ...formData,
          fundingRepaymentPeriod,
          fundingApplicationId: this.fundingApplicationId,
          tradingAgeRanges: this.tradingAgeRanges
        })
        await setFinancialData({
          turnoverLastYear: form.data.turnoverLastYear,
          companyId: this.companyId
        })
        await patchCompany({
          companyId: this.companyId,
          tradingAgeRanges: this.tradingAgeRanges,
          currentlyBank: this.currentlyBank !== 'Other' ? this.currentlyBank : this.currentlyBankOther
        })
        return this.$router.push({ name: this.nextStepName })
      }
      if (!this.fundingApplicationId) {
        await this.setFundingApplication({
          ...formData,
          fundingRepaymentPeriod,
          type: form.data.type === 'All' ? null : form.data.type,
          companyId: this.companyId,
          tradingAgeRanges: this.tradingAgeRanges
        })
      } else {
        await patchFundingApplication({
          ...formData,
          fundingRepaymentPeriod,
          fundingApplicationId: this.fundingApplicationId,
          tradingAgeRanges: this.tradingAgeRanges
        })
      }
      await setFinancialData({
        turnoverLastYear: form.data.turnoverLastYear,
        companyId: this.companyId
      })
      await patchCompany({
        companyId: this.companyId,
        tradingAgeRanges: this.tradingAgeRanges,
        currentlyBank: this.currentlyBank !== 'Other' ? this.currentlyBank : this.currentlyBankOther
      })
      await this.storeRegistrationState(this.companyId)
      return this.$router.push({ name: this.nextStepName })
    },
    async preloadFundingApplicationData(form) {
      if (!this.fundingApplicationId) return
      try {
        const { data } = await getFundingApplication(this.fundingApplicationId)
        const fpPeriod = parseInt(data.fundingRepaymentPeriod, 10) || 0
        const fundingRepaymentPeriodMonth = fpPeriod % 12
        const fundingRepaymentPeriodYears = (fpPeriod - fundingRepaymentPeriodMonth) / 12
        await prefillFormData(form, { ...data, fundingRepaymentPeriodMonth, fundingRepaymentPeriodYears })
      } catch (e) {
        console.error(`Can't preload ${form.formTitle} Form`)
        this.$toasted.show('Something went wrong. We are unable to preload the funding requirements.', { ...toastDefaultOptions, type: 'error', icon: 'error' })
      }
    },
    async init() {
      if (!this.$route.query || !this.$route.query.newBusiness) {
        await this.requestUser()
        await this.preloadFundingApplicationData(this.fundingRequirementsForm)
        await this.loadDocumentsList()
      }
    },
    async loadDocumentsList() {
      if (this.hasUserCompanyAttached) {
        return getDocumentsList(this.companyId)
          .then(res => {
            this.documentsList = res.data
          })
          .catch(e => {
            this.documentsList = []
          })
      }
    },
    documentsListChangeHandler() {
      this.$nextTick(() => this.loadDocumentsList())
    },
    getFieldsByName(group) {
      return flattenGroupFields(group).reduce((acc, field) => ({ ...acc, [field.name]: field }), {})
    },
    sendCompanyAnalytics() {
      this.$ma.trackEvent(amplitudeEvents.fundingOnboarding.ADD_COMPANY)
    }
  },
  created() {
    this.fundingRequirementsForm = deepcopy(fundingForm(false))
    this.init()
  }
}
</script>
