import { render, staticRenderFns } from "./CompanySearch.vue?vue&type=template&id=395f0823&scoped=true&"
import script from "./CompanySearch.vue?vue&type=script&lang=js&"
export * from "./CompanySearch.vue?vue&type=script&lang=js&"
import style0 from "./CompanySearch.vue?vue&type=style&index=0&id=395f0823&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "395f0823",
  null
  
)

export default component.exports